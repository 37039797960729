<template>
	<div :class="['input-set-wrap', ...inputClass]">
		<div class="select-wrap">
			<component
				:is="selectComponent"
				v-bind="{ ...$attrs, ...$props, ...computedProps }"
				:pt="selectPT"
				@update:modelValue="update"
				:invalid="!!errors"
				:class="large ? 'large' : medium ? 'medium' : ''"
			>
				<template v-for="(slotProps, slotName) in $slots" :key="slotName" #[slotName]="slotProps">
					<slot v-if="slotName" :name="slotName" :props="slotProps"></slot>
				</template>
			</component>
			<!-- <div :id="randomId" class="panel-wrap"></div> -->
		</div>
	</div>
	<div v-if="errors" class="mt-2 message-error">{{ errors }}</div>
</template>

<script setup lang="ts">
defineOptions({ inheritAttrs: true })

const randomId = useId()
const abc = ref()
const select = ref()

const props = withDefaults(
	defineProps<{
		modelValue?: any
		type: 'select' | 'multi' | 'tree' | 'org' | undefined
		icon?: string | undefined
		iconPosition?: 'left' | 'right' | '' | undefined
		errors?: string
		filter?: Boolean
		beforeShow?: Function
		expandedKeys?: {} | null
		options: any
		optionLabel?: string
		optionValue?: string
		appendTo?: 'self' | 'body' | HTMLElement | undefined
		block?: boolean
		large?: boolean
		medium?: boolean
		readonly?: boolean
		disabled?: boolean
	}>(),
	{
		beforeShow: (props) => {
			// console.log('beforeShow', props.expandedKeys)
			if (props.expandedKeys) {
				props.expandedKeys = {}
			}
		},
		filter: (props) => !!props.filter || false,
		iconPosition: (props) => (props.icon ? props.iconPosition || 'left' : ''),
		optionLabel: (props) => props.optionLabel || 'label',
		optionValue: (props) => props.optionValue || 'value',
		appendTo: (props) => props.appendTo || 'self'
	}
)

const transition = (a: any) => {
	return {
		onAfterEnter() {
			a.instance.unbindScrollListener()
		}
	}
}

const inputClass = computed(() => [{ 'input-wrap-set-block': props.block }, { 'input-wrap-set-large': props.large }])

const computedProps = computed(() => {
	// console.log('computedProps', props.filter, props.expandedKeys)
	const computed = {} as any
	if (props.filter) {
		computed.expandedKeys = props.expandedKeys ? props.expandedKeys : {}
		computed.beforeShow = props.beforeShow
	}
	// computed.appendTo = '#'+randomId
	computed.appendTo = 'self'
	return computed
})

const selectComponent = computed(() => {
	switch (props.type) {
		case 'tree':
			// if (props.filter) return _TreeSelectWrap
			return 'TreeSelect'
		// break;
		case 'multi':
			return 'MultiSelect'
		// break;
		case 'select':
		default:
			return 'Dropdown'
	}
})

const selectPT = computed(() => {
	switch (props.type) {
		case 'tree':
		// return treePT
		// break;
		case 'multi':
			return { transition }
		// break;
		case 'select':
		default:
			return { transition }
	}
})

const position = computed(() => {
	if (props.iconPosition !== '') return { iconPosition: props.iconPosition }
	return {}
})

const emit = defineEmits(['update:modelValue', 'update:selected'])

const _getSelectedNodes = (nodes, selectedKeys) => {
	const selectedNodes = [] as any
	function findSelectedNodes(node) {
		if (selectedKeys[node.key] === true) {
			selectedNodes.push(node)
		} else if (selectedKeys[node.key]?.checked === true && selectedKeys[node.key]?.partialChecked === false) {
			selectedNodes.push(node)
		}
		if (node.children) {
			node.children.forEach((child: any) => findSelectedNodes(child))
		}
	}

	nodes.forEach((node: any) => findSelectedNodes(node))
	return selectedNodes
}

const update = (value: any) => {
	const selectedNodes = _getSelectedNodes(props.options, value)
	// console.log('update:', value, props.options, selectedNodes)
	// nextTick(() => {
	//   emit('update:selected', selectRef.value.selectedNodes)
	// })
	emit('update:modelValue', value)
	emit('update:selected', selectedNodes)
}

// watch(() => props.modelValue, (value, oValue) => {
//   console.log('watch:', value, oValue)
//   // if (!value || value === oValue) return select.value = undefined
//   select.value = value
// })
</script>

<style lang="scss" scoped>
.input-set-wrap {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	&.input-wrap-set-block {
		// display: block;
		width: 100% !important;
		.select-wrap {
			width: 100% !important;
			:deep(.p-dropdown) {
				width: 100% !important;
			}
			:deep(.p-treeselect) {
				width: 100%;
			}
		}
	}
	&.input-wrap-set-password {
		.right-btns {
			right: 2rem;
		}
	}
}
.select-wrap {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	:deep(.p-dropdown) {
		height: 36px;
		border-radius: 4px;
		box-shadow: none;
		border: 1px solid var(--border-color-1);
		color: var(--Neutral-Gray-700);
		align-items: center;
	}
	:deep(.p-multiselect) {
		height: 36px;
		border-radius: var(--select-radius);
		box-shadow: none;
		border: var(--select-border);
		color: var(--color-neutral-800);
		align-items: center;
	}
	:deep(.p-treeselect) {
		height: 36px;
		border-radius: var(--select-radius);
		box-shadow: none;
		border: var(--select-border);
		color: var(--color-neutral-800);
		align-items: center;
	}
	:deep(.p-dropdown-trigger) {
		height: 36px;
	}
}
.panel-wrap {
	position: relative;
	display: inline-block;
}
</style>
